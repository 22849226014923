<template>
  <div style="height: 100%;width: 100%">
    <div class="pc-index" v-if="isPc">
      <el-carousel indicator-position="none" height="606px" class="carousel-lunbo">
        <el-carousel-item v-for="item in imagesUrl" :key="item">
          <img :src="item" alt="" style="width:100%;height:100%;">
        </el-carousel-item>
      </el-carousel>
      <div class="view-right">
        <div class="right-top">
          <img class="top-img" :src="$fileBaseUrl + '80x80/'+ object.appLogo" alt="">
          <span class="top-title">{{ object.appName }}</span>
        </div>
        <div class="right-center">
          {{ object.appIntroduce }}
        </div>
        <div class="right-bottom">
          <div class="bottom-left">
            <div class="left-rqcode" ref="qrCodeUrl"></div>
          </div>
          <div class="bottom-right">
            <div class="android-dowm" @click="downApp(1)">
              <img class="and-img" src="../assets/img/app-android.png" alt="">
              Android下载
            </div>
            <div class="apple-down" @click="downApp(2)">
              <img class="and-img" src="../assets/img/app-apple.png" alt="">
              AppStore下载
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isPc" class="app-index">
      <img class="app-img" src="../assets/img/app-wql.png" alt="">
      <span class="app-title">{{ object.appName }}</span>
      <span class="app-intro">{{ object.appIntroduce }}</span>
      <div class="app-down">
        <div class="android-dowm" @click="downApp(1)">
          <img class="and-img" src="../assets/img/app-android.png" alt="">
          Android下载
        </div>
        <div class="apple-down" @click="downApp(2)">
          <img class="and-img" src="../assets/img/app-apple.png" alt="">
          AppStore下载
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import QRCode from 'qrcodejs2'

export default {
  data() {
    return {
      isPc: true,
      //imagesUrl: this.$lunboList,//[require("../assets/img/lunbo1.png"), require("../assets/img/lunbo2.png"), require("../assets/img/lunbo3.png")],
      downUrl: "",
      downName: "",
      object:''
    };
  },
  created() {
    this.getDownUrl();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isMobile()) {
        this.isPc = false
      } else {
        this.isPc = true
      }
    })
  },
  computed:{
    imagesUrl:function(){
      if(this.object && this.object.imgList){
        return this.object.imgList.map(img=>this.$fileBaseUrl+"300x/"+img.filePath)
      } else {
        return []
      }

    }
  },
  methods: {
    getDownUrl() {
      this.$axios.get(`/OpencloudManager/AppVersion/AppPromotionPage`).then((res) => {
        this.object = res;
        this.creatQrCode()
      })
    },
    //type =1 是安卓  2 是苹果
    downApp(type) {
      if (type == 1) {
        this.downFile(this.$ossUrl + "/" + this.object.andRoidUrl, this.object.appName)
      } else {
        window.open(this.object.appStoreUrl)
      }
    },
    // 添加判断方法
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    creatQrCode() {
      this.qrcodeCreater = new QRCode(this.$refs.qrCodeUrl, {
        text: this.object.visitUrl, // 需要转换为二维码的内容
        width: 148,
        height: 148,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    downFile(url, filename) {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.download = filename + ".apk";
      document.body.appendChild(link);
      link.click();
      /* const xhr = new XMLHttpRequest();
       xhr.open('GET', url, true);
       xhr.responseType = 'blob';
       xhr.onload = () => {
         if (xhr.status === 200) {
           if (window.navigator.msSaveOrOpenBlob) {
             navigator.msSaveBlob(xhr.response, filename);
           } else {
             const link = document.createElement('a');
             const body = document.querySelector('body');
             link.href = window.URL.createObjectURL(xhr.response);
             link.download = filename;
             // fix Firefox
             link.style.display = 'none';
             body.appendChild(link);
             link.click();
             body.removeChild(link);
             window.URL.revokeObjectURL(link.href);
           }
         }
       };
       xhr.send();*/
    },
  },
};
</script>

<style lang="scss" scoped>
.and-img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.app-index {
  height: 100%;
  width: 100%;
  background: url("../assets/img/app-back.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .app-down {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;

    .android-dowm {
      width: 260px;
      height: 58px;
      opacity: 1;
      border: 1px solid #ffffff;
      border-radius: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 22px;
      font-size: 17px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;

      color: #d62b21;
      line-height: 17px;
      background: #ffffff;
    }

    .apple-down {
      width: 260px;
      height: 58px;
      opacity: 1;
      border: 1px solid #ffffff;
      border-radius: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #ffffff;
      line-height: 17px;
    }
  }

  .app-img {
    width: 128px;
    height: 128px;
  }

  .app-title {
    margin-top: 43px;
    font-size: 30px;
    font-family: hanSans;
    font-weight: 500;
    color: #ffffff;
    line-height: 32px;
  }

  .app-intro {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #ffffff;
    //margin-top: 22px;
    margin: 10px 50px;
    line-height:24px
  }
}

.pc-index {
  height: 100%;
  width: 100%;
  background: url("../assets/img/back.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .carousel-lunbo {
    width: 298px;
    height: 606px;
  }
}

.view-right {
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;

  .right-bottom {
    display: flex;
    margin-top: 100px;

    .bottom-left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 160px;
      padding: 6px;
      background-color: white;
      border-radius: 16px;
      margin-right: 60px;

      .left-rqcode {

      }
    }

    .bottom-right {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .android-dowm {
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #d62b21;
        width: 220px;
        height: 60px;
        background: #ffffff;
        border-radius: 102px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 22px;
        cursor: pointer;
      }

      .apple-down {
        cursor: pointer;
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #ffffff;
        width: 220px;
        height: 60px;
        opacity: 1;
        border: 1px solid #ffffff;
        border-radius: 103px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .right-center {
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #ffffff;
    line-height: 24px;
    letter-spacing: 1px;
  }

  .right-top {
    display: flex;
    align-items: center;

    .top-img {
      width: 78px;
      height: 78px;
    }

    .top-title {
      font-size: 76px;
      font-family: hanSans-bold;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 10px;
      margin-left: 30px;
    }
  }
}

</style>
